import { BaseModel } from 'shared/models/base.model';
import { SpotBidExecutionAdditionalShipmentInformationV3 } from './spot-bid-execution-additional-shipment-information-v3.model';
import { SpotBidExecutionLoadRequirementsV3 } from './spot-bid-execution-load-requirements-v3.model';
import { SpotBidExecutionStopV3 } from './spot-bid-execution-stop-v3.model';

export class SpotBidExecutionShipmentV3 extends BaseModel {
  loadNumber: number;
  loadRequirements: SpotBidExecutionLoadRequirementsV3;
  additionalShipmentInformation: SpotBidExecutionAdditionalShipmentInformationV3;
  stops: SpotBidExecutionStopV3[];
  commodityNames: string[];
  pickUpReadyByDate?: Date;
  pickUpByDate?: Date;
  deliveryReadyDate?: Date;
  deliverByDate?: Date;
  firstPickActivityDate?: Date;
  lastDropActivityDate?: Date;

  constructor(json) {
    super(json);
    this.commodityNames = json.commodityNames as string[];
  }
}
