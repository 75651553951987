export class ReadConversationByRelatedEntityRequest{
    relatedEntityId: string;
    relatedEntityType: string;

    constructor(relatedEntityId: string, relatedEntityType: string) {
        this.relatedEntityId = relatedEntityId,
        this.relatedEntityType = relatedEntityType
    }
}

export class CreateConversationCommentRequest{
    relatedEntityId: string;
    relatedEntityType: string;
    createdByUser: string;
    createdByParty: string;
    comment: string;
}

export class MarkConversationReadByRelatedEntityRequest{
    relatedEntityId: string;
    relatedEntityType: string;
    readByUser: string;
    readByParty: string;
}