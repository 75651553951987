import { API_DATE_FORMAT } from '@app/globals/constants';
import moment from 'moment';

export interface MenuItem {
  label: keyof IResources | string;
  value: number;
  sortDirection?: number;
}

export interface Load {
  driver?: {
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
  };
  loadNumber: number;
  expectedPickupDate: Date;
  deliverByDate: Date;
  origin: {
    city?: string;
    country?: string;
    zipCode?: string;
    countryCode?: string;
    fullLocation?: string;
    stateCode: string;
    location: string;
  };
  destination: {
    city?: string;
    country?: string;
    zipCode?: string;
    countryCode?: string;
    fullLocation?: string;
    stateCode: string;
    location: string;
  };
  driverFirstName: string;
  driverLastName: string;
  trackingStatus: number;
  status: {
    code: number;
    description: string;
  };
  pickupNumber: string;
  proNumber: string;
  trailerNumber: string;
  tractorNumber: string;
  trackingError: string;
  trackingIdentifier: number;
  trackingMethod: number;
  trackingMessageCode: number;
}

interface TrackingStatus {
  bookTrackingStatusId?: string;
  identifierType?: string;
  trackingMessageCode?: number;
  trackingMethodType?: string;
  trackingStatusType?: string;
  trackingIssueStatus?: string;
  errorMessage?: string;
  trackingStatusSortOrder?: number;
  trackingIdentifierCode: TrackingIdentifierType;
  trackingMethodCode: TrackingMethodType;
}

export interface BookLoadUpdateNotification {
    shipmentId: string;
    bookId: string;
    tractorNumber: string;
    trailerNumber: string;
    proNumber: string;
    licencePlateNumber: string;
    contactId: string;
    driverFirstName: string;
    driverLastName: string;
  }

  export interface Driver {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phone?: {
      areaCode?: string;
      number?: string;
      extension?: null;
    };
    mdmContactId?: number;
    isMain?: boolean;
    landId?: number;
    branch?: string;
    trackingLogs?: any[];
    assignedDateTime?: Date;
  }

  export interface Appointment {
    closeDateTime?: string;
    isCloseTimeSet?: boolean;
    isOpenTimeSet?: boolean;
    number?: string;
    openDateTime?: string;
    rep?: string;
    scheduledBy?: string;
    scheduledCompany?: string;
    schedulingOptionCode?: string;
    scheduledEnteredDataTimeUtc?: string;
    scheduledWithFirstName?: string;
    scheduledWithLastName?: string;
    statusCode?: number;
  }

  export interface ShipmentStop {
    stopId: string;
    stopNumber: number;
    stopLocation: {
      address: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        postalCode?: string;
        countryCode?: string;
        countryName?: string;
        county?: string;
        coordinate?: {
          lat: number;
          lon: number;
        }
      };
      warehouse: {
        warehouseCloseTime?: string;
        warehouseCode?: string;
        warehouseCustomerLocationNumber?: string;
        warehouseName?: string;
        warehouseOpenTime?: string;
        warehousePhoneNumber?: string;
      };
    };
    requiredDriverWorkCode?: number;
    stopType?: number;
    activities?: [
      {
        stopId?: number;
        bookIds?: string[];
        appointment?: Appointment
        stopActivityId?: string;
        arrivedDateTime?: Date;
        departureDateTime?: Date;
        requestedDateTime?: Date;
        scheduledEnteredDateTimeUtc?: Date;
      }
    ];
    timeZoneId?: string;
    referenceNumber?: string;
  }
  
export interface ShipmentStopActivity {
  stopId?: number;
  appointment?: Appointment
  stopActivityId?: string;
  arrivedDateTime?: Date;
  departureDateTime?: Date;
  requestedDateTime?: Date;
  scheduledEnteredDateTimeUtc?: Date;
  itemIds: string[];
}

  export interface Commodity {
    commodityName: string;
    pallets?: number;
    weight?: Measurement;
    poNumber?: number;
    pieces?: number;
  }

  export interface Measurement {
    standard: number;
    metric: number;
  }

  export interface LoadDetails {
    commodities?: Commodity[];
    billToReferenceNumbers?: string[];
    ediReferenceNumbers?: string[];
  }

  export interface Equipment {
    length?: {
      metric: number;
      standard: number
    };
    width?: {
      metric: number;
      standard: number
    };
    height?: {
      metric: number;
      standard: number
    };
    code?: string;
    description?: string;
  
  }

  export interface LoadDetails {
    commodities?: Commodity[];
    billToReferenceNumbers?: string[];
    ediReferenceNumbers?: string[];
    books?: FinancialParty[];
    billTos?: FinancialParty[];
    functionalCurrencyCode?: string;
  }

declare interface FinancialParty {
  currencyCode: string;
  exchangeRate: number;
  financialAmounts: FinancialAmount[];
  financialPartyId: string;
  hasGeneratedFinances: boolean;
  isExempt: boolean;
  partyCode: string;
}

declare interface FinancialAmount {
  code: string;
  description: string;
  enteredBy: string;
  enteredDateTimeUtc: string;
  financialAmountId: string;
  isAutoRated: boolean;
  isChargeBack: boolean;
  sourceDataId: string;
  sourceSystem: string;
  sourceValue: number;
  sourceUnits: number;
  stopId: string;
  taxes: Tax[];
  type: string;
  updatedBy: string;
  updatedDateTimeUtc: string | null;
  isCreditNote: boolean;
  rateSource: string;
  costingModelKey: string;
  contractId: number | null;
}

export interface Equipment {
  length?: {
    metric: number;
    standard: number
  };
  width?: {
    metric: number;
    standard: number
  };
  height?: {
    metric: number;
    standard: number
  };
  code?: string;
  description?: string;

}

export enum UnitOfMeasure {
  STANDARD = 1,
  METRIC = 2
}

export enum TrackingIdentifierType {
  None = 0,
  TractorNumber = 1,
  TrailerNumber = 2,
  CellPhoneNumber = 3,
  Name = 4,
  LicensePlate = 5,
  ProNumber = 6,
}

export enum TrackingMethodType {
  None = 0,
  CarrierApi = 1,
  CarrierApp = 2,
  DriverApp = 3,
  Edi = 4,
  GpsAggregator = 5,
  Macropoint = 6,
}

export interface LoadSummary {
  loadNumber: number;
  bookId: string;
  loadStatus: number;
  isPendingState?: boolean;
  tracking?: TrackingStatus;
  driver?: Driver;
  trailerNumber?: string;
  tractorNumber?: string;
  proNumber?: string;
  licensePlateNumber?: string;
  weight?: string;
  firstPick: ShipmentDropPick;
  lastDrop: ShipmentDropPick;
  stops?: ShipmentStop[];
  modes?: string;
  loadDetails?: LoadDetails;
  equipment?: Equipment;
  unitOfMeasure?: UnitOfMeasure;
  bookType?: string;
  extendedLoad?: any;
  financials?: Financials;
  items?: Item[];
  distance?: Distance;
  settings?: {
    isHazmat?: boolean,
  };
  pickUpByDate?: Date;
  deliverByDate?: Date;
  calculatedPickUpDateTime?: Date;
  calculatedDeliverByDateTime?: Date;
  specializedEquipmentCode?: string;
  notes?: [{note: string, translationKey: string}];
}

  export interface Financials {
    options?: CashAdvanceOptions;
    financialHistory?: CashAdvances[];
  }

  export interface Item {
    itemId: string;
    actualWeight: Measurement;
  }

  export interface Distance {
    miles: number;
    kilometers: number;
  }

  export interface CashAdvances {
    cashAdvanceId?: number;
    sourceType?: number;
    totalAmount?: number;
    sourceFeeAmount?: number;
    carrierCode?: string;
    requestedDate?: Date;
    requestedBy?: string;
    status?: string;
    sourceCurrencyCode?: string;
    remainingAmount?: number;
    cashedDate?: Date;
    expirationDate?: Date;
  }
  
  export interface CashAdvanceOptions {
    availableBalance?: number;
    sourceCurrencyCode?: string;
    carrierIsEnabled?: boolean;
    carrierDisabledReason?: string;
    loadIsEnabled?: boolean;
    loadDisabledReason?: string;
    promotionId?: number;
    validationErrors?: CashAdvanceErrors[];
    cashAdvanceSources?: CashAdvanceSources[];
  }
  
  export interface CashAdvanceErrors {
    message?: string;
    code?: string;
  }
  
  export interface CashAdvanceSources {
    cashAdvanceSourceType?: number;
    isEnabled?: boolean;
    minimumFee?: number;
    percentFee?: number;
    settings?: {
      isHazmat?: boolean,
    };
    pickUpByDate?: Date;
    deliverByDate?: Date;
    processStatuses?: [];
  }
  
  export interface FetchMyLoadsCriteria {
    status?: number;
    pageNumber?: number;
    itemsPerPage?: number;
    loadStatus?: string;
    loadNumber?: string;
    proNumber?: string;
    referenceNumber?: string;
    tractorNumber?: string;
    trailerNumber?: string;
    driverId?: string;
    startDate?: string;
    endDate?: string;
    city?: string;
    state?: string;
    sortField?: number;
    sortDirection?: number;
  }
  
  export interface DocumentJSON {
    StopSeqNum?: number;
    FileName: string;
    LoadNumber: number;
    Type: number;
    CarrierCode: string;
    Amount?: number;
    InvoiceNumber?: string;
    Currency?: number;
    FuelSurcharge?: number;
  }
  
  export interface UpdateBook {
    proNumber?: string;
    tractorNumber?: string;
    trailerNumber?: string;
    licensePlateNumber?: string;
    drivers?: LoadDriver[];
  }
  export interface LoadDriver {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    contactId?: number;
    sequenceNumber?: number;
    driverType: number;
  }

export class StopUpdate {
  address: AddressUpdate;
  arrivedAtStop: string;
  bol: string;
  calculateETA: boolean;
  closeDateTime: string;
  completed: string;
  completedBy: string;
  completedByDateTime: string;
  departedStop: string;
  driverWork: string;
  dropTrailer: string;
  eta: string;
  expressStopType: string;
  infoFrom?: string;
  items: any[];
  hazMat: boolean;
  loadNumber: number;
  loadType: number;
  maxExpectedWeight: number;
  name: string;
  notes: string;
  openDateTime: string;
  pallets: number;
  parentLoad?: number;
  phoneNumber: string;
  pod: string;
  product: string;
  recordedTemperature: string;
  referenceNumber: string;
  referenceNumbers: any[];
  requestedDate: string;
  requestedDateIsAllDay: boolean;
  scheduledEnd: string;
  scheduledStart: string;
  scheduleReq: {
    instructions: string;
    instructionType: number;
  };
  scheduleRequired: {
    instructions: string;
    instructionType: number;
  };
  scheduleWith: string;
  scheduleWithFirstName: string;
  scheduleWithLastName: string;
  sealNumber: string;
  sequenceNumber: number;
  stopDates: string;
  stopHours: string;
  stopIsRamp: boolean;
  stopLoadNumber: number;
  stopNote?: string;
  stopNumber: String;
  stopType: number;
  warehouseCode: string;
  warehouseDirections: string;
  warehouseNotes: string;
  weight: number;
  reasonCode: string;

  constructor(loadSummary: LoadSummary, stop: ShipmentStop, departDateTime?: Date, arriveDateTime?: Date, lateReason?: string) {
    this.address = new AddressUpdate();
    this.address.updateAddressForStop(stop);
    this.arrivedAtStop = arriveDateTime === undefined ?
      stop?.activities[0]?.arrivedDateTime === null ? '0001-01-01T00:00:00' :
        moment(stop?.activities[0]?.arrivedDateTime)?.format(API_DATE_FORMAT) :
      moment(arriveDateTime).format(API_DATE_FORMAT);
    this.bol = '';
    this.calculateETA = false;
    this.closeDateTime = moment(new Date()).format(API_DATE_FORMAT);
    this.completed = '0001-01-01T00:00:00';
    this.completedBy = '';
    this.completedByDateTime = '0001-01-01T00:00:00';
    this.departedStop = departDateTime === undefined ?
      stop?.activities[0]?.departureDateTime === null ? '0001-01-01T00:00:00' :
        moment(stop?.activities[0]?.departureDateTime)?.format(API_DATE_FORMAT) :
      moment(departDateTime).format(API_DATE_FORMAT);
    this.eta = '0001-01-01T00:00:00';
    this.expressStopType = 'p';
    this.infoFrom = null;
    this.items = [];
    this.hazMat = loadSummary?.settings?.isHazmat ?? false;
    this.loadNumber = loadSummary?.loadNumber;
    this.maxExpectedWeight = 0;
    this.name = '';
    this.notes = '';
    this.openDateTime = moment(new Date()).format(API_DATE_FORMAT); // TODO
    this.pallets = 0;
    this.parentLoad = null;
    this.phoneNumber = '';
    this.pod = '';
    this.product = '';
    this.recordedTemperature = '';
    this.referenceNumber = '';
    this.referenceNumbers = [];
    this.requestedDate = stop?.activities[0]?.requestedDateTime !== undefined ?
      moment(stop.activities[0].requestedDateTime).format(API_DATE_FORMAT) :
      '0001-01-01T00:00:00';
    this.requestedDateIsAllDay = false;
    this.scheduledEnd = '0001-01-01T00:00:00';
    this.scheduledStart = '0001-01-01T00:00:00';
    this.scheduleReq = {
      instructions: '',
      instructionType: 0
    };
    this.scheduleRequired = {
      instructions: '',
      instructionType: 0
    };
    this.scheduleWith = '';
    this.scheduleWithFirstName = stop?.activities[0]?.appointment?.scheduledWithFirstName;
    this.scheduleWithLastName = '';
    this.sealNumber = '';
    this.sequenceNumber = 0;
    this.stopDates = '';
    this.stopHours = '';
    this.stopIsRamp = false;
    this.stopLoadNumber = 0;
    this.stopNote = '';
    this.stopNumber = stop.stopNumber.toString();
    this.stopType = stop.stopType;
    this.warehouseCode = stop?.stopLocation?.warehouse?.warehouseCode;
    this.warehouseDirections = '';
    this.warehouseNotes = '';
    this.weight = 0;
    this.reasonCode = lateReason ?? '';
  }
}
  export interface ShipmentDropPick {
    stopId: string;
    stopNumber: number;
    referenceNumber?: string;
    stopLocation: {
      address: {
        addressLine1?: string;
        addressLine2?: string;
        city?: string;
        state?: string;
        postalCode?: string;
        countryCode?: string;
        countryName?: string;
        county?: string;
        coordinate?: {
          lat: number;
          lon: number;
        }
      };
      warehouse: {
        warehouseCloseTime?: string;
        warehouseCode?: string;
        warehouseCustomerLocationNumber?: string;
        warehouseName?: string;
        warehouseOpenTime?: string;
        warehousePhoneNumber?: string;
      };
    };
    requiredDriverWorkCode?: number;
    stopType?: number;
    searchAppointment?: Appointment;
    timeZoneId?: string;
  }

export interface CreateAppointment {
    shipmentId: string;
    bookId: string;
    stopActivityId?: string;
    stopId: string;
    scheduledBy: string;
    scheduledWithFirstName: string;
    scheduledWithLastName?: string;
    carrierCode: string;
    openDateTime: Date;
    closeDateTime: Date;
}

  interface TrackingStatus {
    bookTrackingStatusId?: string;
    identifierType?: string;
    trackingMessageCode?: number;
    trackingMethodType?: string;
    trackingStatusType?: string;
    trackingIssueStatus?: string;
    errorMessage?: string;
    trackingStatusSortOrder?: number;
    trackingIdentifierCode: TrackingIdentifierType;
    trackingMethodCode: TrackingMethodType;
  }

  export class LoadSummaryUpdate {
    loadNumber: number;
    status: LoadStatusUpdate;
    activityDate: string;
    deliverByDate: string;
    deliveryAvailableDate: string;
    readyDate: string;
    carrierID: string;
    stops: StopUpdate[];
    activeBooking: BookingUpdate;
    items: any[];
    loadType: number;
    trackingStatus: number;
    trackingError: string;
    trackingIdentifier: number;
    trackingMethod: number;
    trackingMessageCode: number;
  
    constructor(load: LoadSummary, carrierCode: string) {
      this.loadNumber = load.loadNumber;
      this.status = new LoadStatusUpdate(load.loadStatus);
      this.carrierID = carrierCode;
      this.stops = load.stops?.map(stop => new StopUpdate(load, stop));
      this.activeBooking = new BookingUpdate(load, carrierCode);
      this.items = [];
      this.loadType = 3;
    }
  }

  export class BookingUpdate {
    loadNumber: number;
    acceptedBy?: string;
    acceptReject?: string;
    actualEmptyDateTime: string;
    bookedBY: string;
    driver1FirstName?: string;
    driver1LastName?: string;
    driver2FirstName?: string;
    driver2LastName?: string;
    driverCellPhone?: string;
    driverEmpty: boolean;
    expressLoadNumber: number;
    expectedDeliveryDateTime: string;
    expectedEmptyDateTime: string;
    expectedPickUpDateTime: string;
    bookType: string;
    actualEmptyAddress: AddressUpdate;
    bookingNumber?: string;
    expectedEmptyAddress: AddressUpdate;
    carrierLoadContact?: string;
    checkCalls: [];
    currencyCode: string;
    documents: [];
    expDelDateTime: string;
    finLocked: boolean;
    imProgram?: string;
    infoFrom?: string;
    name: string;
    proNumber: string;
    proNumberRequiredLength: number;
    rejectedReason: string;
    scac?: string;
    scheduledEndDate: string;
    sequenceNumber: number;
    trailerNumber: string;
    driverCheckCalls: [];
    bookStops: BookStopUpdate[];
    carrier: CarrierUpdate;
    carrierEquipment: CarrierEquipmentUpdate;
    salesRep: SalesRepUpdate;
  
    constructor(load: LoadSummary, carrierCode: string) {
      this.loadNumber = load.loadNumber;
      this.expressLoadNumber = load.loadNumber;
      this.bookType = load.bookType;
      this.bookingNumber = null;
      this.driver1FirstName = load.driver?.firstName;
      this.driver1LastName = load.driver?.lastName;
      this.driverCellPhone = load.driver?.phone.number;
      this.proNumber = load.proNumber;
      this.driver2FirstName = null;
      this.driver2LastName = null;
      this.actualEmptyAddress = new AddressUpdate();
      this.expectedEmptyAddress = new AddressUpdate();
      this.carrierEquipment = new CarrierEquipmentUpdate(load);
      this.carrier = new CarrierUpdate(carrierCode);
      this.salesRep = new SalesRepUpdate();
      this.bookStops = load.stops?.map(stop => new BookStopUpdate(stop));
      this.trailerNumber = load.trailerNumber;
      this.carrierLoadContact = null;
      this.checkCalls = [];
      this.finLocked = false;
      this.imProgram = null;
      this.infoFrom = null;
      this.proNumberRequiredLength = 0;
    }
  }
  
  export class LoadStatusUpdate {
    code: number;
    description?: string;
  
    constructor(status: number) {
      this.code = status;
    }
  }

  export class AddressUpdate {
    streetName: string;
    street2: string;
    cityName: string;
    stateCode: string;
    zipcode: string;
    country: string;
    county: string;
    location: string;
    fullLocation: string;
    companyName: string;
  
    constructor() {
      this.streetName = '';
      this.street2 = '';
      this.cityName = '';
      this.stateCode = '';
      this.zipcode = '';
      this.country = '';
      this.county = '';
      this.location = '';
      this.fullLocation = '';
      this.companyName = '';
    }
  
    updateAddressForStop(stop: ShipmentStop) {
      this.streetName = stop.stopLocation.address.addressLine1;
      this.street2 = stop.stopLocation.address.addressLine2;
      this.cityName = stop.stopLocation.address.city;
      this.stateCode = stop.stopLocation.address.state;
      this.zipcode = stop.stopLocation.address.postalCode;
      this.country = stop.stopLocation.address.countryCode;
      this.county = stop.stopLocation.address.county;
      this.location = stop.stopLocation.warehouse.warehouseCode;
      this.fullLocation = stop.stopLocation.warehouse.warehouseName;
      this.companyName = stop.stopLocation.warehouse.warehouseName;
    }
  }
  
  export class CarrierEquipmentUpdate {
    attributeFailedRequirement: number;
    tractorNumber?: string;
    trailerNumber?: string;
    typeDescription: string;
    type?: string;
    height: number;
    length: number;
    width: number;
  
    constructor(load: LoadSummary) {
      this.attributeFailedRequirement = 0;
      this.tractorNumber = load.tractorNumber;
      this.trailerNumber = load.trailerNumber;
    }
  }
  
  export class CarrierUpdate {
    code: string;
    name: string;
    isTMC: boolean;
    regionCode: string;
    isDray: boolean;
    carrierTier: number;
    paymentType: string;
  
    constructor(carrierCode: string) {
      this.code = carrierCode;
    }
  }
  
  export class SalesRepUpdate {
    branchCode: string;
    branchName: string;
    branchPhone: string;
    contactName: string;
    email: string;
    firstName: string;
    isActive: boolean;
    lanId: string;
    lastName: string;
    workPhone: string;
  
    constructor() {
      this.branchCode = '';
    }
  }
  
  export class BookStopUpdate {
    bookStopNumber: number;
    bookStopSequence: number;
  
    constructor(stop: ShipmentStop) {
      this.bookStopNumber = 0;
      this.bookStopSequence = stop.stopNumber;
    }
  }
