import { BaseModel } from 'shared/models/base.model';

export class SpotBidDetailV2 extends BaseModel {
  id: string;
  createdBy: SpotBidV2User;
  note: string;
  autoAcceptAmount: number;
  closeByDateTimeUtc: Date;
  createdDateTimeUtc: Date;
  expirationDateTimeUtc?: Date;
  effectiveDateTimeUtc?: Date;
  lastUpdatedDateTimeUtc?: Date;
  currencyCode: string;
  customer: SpotBidV2Customer;
  shipment: SpotBidV2Shipment;
  spotBidCarriers: SpotBidV2Carrier[];
  numberOfRequestedTrucks?: number;
  flags:  SpotBidV2Flags;
  status: string;

  constructor(json?: any) {
    super(json);
  }

  getCarrier(): SpotBidV2Carrier {
    if (this.spotBidCarriers && this.spotBidCarriers.length) {
      return this.spotBidCarriers[0];
    }
    return null;
  }

  getExistingCarrierBid(): SpotBidV2CarrierBid {
    const carrier = this.getCarrier();
    if (carrier && carrier.bids && carrier.bids.length) {
      return carrier.bids.filter(x => x.status === 'Open')[0];
    }

    return null;
  }

  getProposedCarrierBid(): SpotBidV2CarrierBid {
    const carrier = this.getCarrier();
    if (carrier && carrier.bids && carrier.bids.length) {
      return carrier.bids.filter(x => x.status === 'Proposed')[0];
    }

    return null;
  }

  getProposedCarrierBidCosts(filterOutZeroCost: boolean = false): SpotBidV2CarrierBidCost[] {
    const carrierBid = this.getProposedCarrierBid();
    if(carrierBid && carrierBid.carrierBidCosts) {
      return filterOutZeroCost ? carrierBid.carrierBidCosts.filter(cost => cost.costPerUnit !== 0) : carrierBid.carrierBidCosts;
    }

    return null;
  }

  getProposedCarrierBidFirstCost(): SpotBidV2CarrierBidCost {
    const carrierBidCosts = this.getProposedCarrierBidCosts();
    return carrierBidCosts && carrierBidCosts.length ? carrierBidCosts[0] : null;
  }
}
