import { BaseModel } from 'shared/models/base.model';
import { SpotBidCarrierBidCostV3 } from './spot-bid-carrier-bid-cost-v3.model';
import { SpotBidCarrierBidV3 } from './spot-bid-carrier-bid-v3.model';
import { SpotBidCarrierV3 } from './spot-bid-carrier-v3.model';
import { SpotBidCustomerV3 } from './spot-bid-customer-v3.model';
import { SpotBidLowestCarrierBidAmountV3 } from './spot-bid-lowest-carrier-bid-amount-v3.model';
import { SpotBidShipmentV3 } from './spot-bid-shipment-v3.model';
import { SpotBidUserV3 } from './spot-bid-user-v3.model';
import { SpotBidV3Flags } from './spot-bids-flags-v3.model';

export class SpotBidDetailV3 extends BaseModel {
  id: string;
  createdBy: SpotBidUserV3;
  note: string;
  autoAcceptAmount: number;
  closeByDateTimeUtc: Date;
  createdDateTimeUtc: Date;
  expirationDateTimeUtc?: Date;
  effectiveDateTimeUtc?: Date;
  lastUpdatedDateTimeUtc?: Date;
  currencyCode: string;
  customer: SpotBidCustomerV3;
  shipment: SpotBidShipmentV3;
  spotBidCarriers: SpotBidCarrierV3[];
  numberOfRequestedTrucks?: number;
  flags: SpotBidV3Flags;
  status: string;
  lowestCarrierBidAmount: SpotBidLowestCarrierBidAmountV3;

  get carrier(): SpotBidCarrierV3 {
    if (this.spotBidCarriers && this.spotBidCarriers.length) {
      return this.spotBidCarriers[0];
    }
    return null;
  }

  get isContractedRateEnabled(): boolean {
    if (!this.flags.onlyCarrierWithoutContracts && this.spotBidCarriers[0].bids.filter(x => x.status === 'Proposed')[0]) {
      return true;
    }
    return false;
  }

  get isSpotRateEnabled(): boolean {
    return !this.flags.onlyCarrierWithContracts;
  }

  get proposedCarrierBid(): SpotBidCarrierBidV3 {
    const carrier = this.carrier;
    if (carrier && carrier.bids && carrier.bids.length) {
      return carrier.bids.filter(x => x.status === 'Proposed')[0];
    }

    return null;
  }

  getProposedCarrierBidCosts(filterOutZeroCost: boolean = false): SpotBidCarrierBidCostV3[] {
    const carrierBid = this.proposedCarrierBid;
    if (carrierBid && carrierBid.carrierBidCosts) {
      return filterOutZeroCost ? carrierBid.carrierBidCosts.filter(cost => cost.costPerUnit !== 0) : carrierBid.carrierBidCosts;
    }

    return null;
  }

  get quoteId(): string {
    const proposedBid = this.proposedCarrierBid;
    if (!proposedBid?.carrierBidCosts) {
      return null;
    }

    const lineHaulCost = proposedBid.carrierBidCosts.filter(x => x.costCode === '400')[0];

    return lineHaulCost?.sourceId ?? null;
  }
}
