import moment from 'moment-timezone';

import { BaseModel } from 'shared/models/base.model';

export class InvoiceComment extends BaseModel<InvoiceCommentJSON> {
  messageId: number; // mapped from ID
  message: string; // mapped from Note
  username: string; // mapped from UserID
  sourceApplication: string; // mapped from SourceApplication ERNoteSourceApplication
  createdDateString: string;
  createdDateTime: Date; // mapped from CreatedDate
  customerCode: string; // mapped from CCode
  carrierCode: string; // mapped from TCode
  hasBeenViewed: boolean; // mapped from IsViewed
  createdByParty: string;

  constructor(json?: InvoiceCommentJSON) {
    super(json);
    this.createdDateString = json?.createdDateTime;
    this.createdDateTime = json && json.createdDateTime && this.processDate(moment.tz(json?.createdDateTime, moment.tz.guess()).toString());
  }
  toJson(): InvoiceCommentJSON {
    return Object.assign(
      {},
      super.toJson(),
      {
        createdDateTime: this.createdDateTime && this.convertDateToDateString(this.createdDateTime)
      }
    );
  }
}
