import { BaseModel } from 'shared/models/base.model';
import { AccesorialCodeV3 } from './spot-bid-accessorial-code-v3.model';
import { SpotBidExecutionShipmentV3 } from './spot-bid-execution-shipment-v3.model';
import { SpotBidPolicyPermissionV3 } from './spot-bid-policy-permissions-v3.enum';

export class SpotBidShipmentDetailsV3 extends BaseModel {
  customerPolicyPermissions: SpotBidPolicyPermissionV3[];
  shipment: SpotBidExecutionShipmentV3;
  accessorialCodes: AccesorialCodeV3[];

  hasPermission(permission: SpotBidPolicyPermissionV3) {
    if (!this.customerPolicyPermissions) {
      return false;
    }
    return Boolean(this.customerPolicyPermissions.find(policy => policy === permission));
  }

  constructor(json) {
    super(json);
    this.shipment = new SpotBidExecutionShipmentV3(json.shipment);
    this.customerPolicyPermissions = (json.customerPolicyPermissions as string[]).map(x => x as SpotBidPolicyPermissionV3);
  }
}
